import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import './Historinhas.css';

const Historinhas = () => {
  const [theme, setTheme] = useState('');
  const [story, setStory] = useState('');
  const [email, setEmail] = useState('');
  const [stories, setStories] = useState([]);
  const [voices, setVoices] = useState([]);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);
    };

    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;
  }, []);

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    const storedToken = localStorage.getItem('token');

    if (storedEmail) {
      setEmail(storedEmail);
    } else {
      console.error('E-mail do usuário não encontrado no localStorage');
    }

    if (storedToken) {
      setToken(storedToken);
    } else {
      console.error('Token do usuário não encontrado no localStorage');
    }
  }, []);

  const getStory = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        'https://mamaeconecta.com.br/api/get-story',
        { theme },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setStory(response.data.story);
      await saveStory(response.data.story);
    } catch (error) {
      console.error('Erro ao obter a historinha', error);
    } finally {
      setLoading(false);
    }
  };

  const saveStory = async (story) => {
    try {
      await axios.post(
        'https://mamaeconecta.com.br/api/save-story',
        { email, story },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      fetchStories();
    } catch (error) {
      console.error('Erro ao salvar a historinha', error);
    }
  };

  const fetchStories = async () => {
    try {
      const response = await axios.get('https://mamaeconecta.com.br/api/get-stories', {
        params: { email },
        headers: { Authorization: `Bearer ${token}` }
      });
      setStories(response.data.stories);
    } catch (error) {
      console.error('Erro ao obter o histórico de historinhas', error);
    }
  };

  const playStory = (text) => {
    const speech = new SpeechSynthesisUtterance(text);
    const portugueseVoice = voices.find(voice => voice.lang === 'pt-BR');
    speech.voice = portugueseVoice || voices[0];
    speech.rate = 1;

    speech.onstart = () => console.log('Início da leitura');
    speech.onerror = (e) => console.error('Erro na leitura:', e);

    window.speechSynthesis.speak(speech);
  };

  useEffect(() => {
    if (email && token) {
      fetchStories();
    }
  }, [email, token]);

  const handlePlayStory = (text) => {
    const range = document.createRange();
    const selection = window.getSelection();
    const storyElement = document.querySelector('#story-text');

    if (storyElement) {
      range.selectNodeContents(storyElement);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    playStory(text);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    navigate('/login');
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className={`historinhas-container ${sidebarOpen ? 'sidebar-open' : ''}`}>
      <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} onLogout={handleLogout} />
      <div className="content">
        <h1>Crie sua historinha</h1>
        <input
          type="text"
          value={theme}
          onChange={(e) => setTheme(e.target.value)}
          placeholder="Digite o tema da historinha"
          className="theme-input"
        />
        <button onClick={getStory} disabled={loading} className="create-button">
          {loading ? 'Carregando...' : 'Criar Historinha'}
        </button>
        <div className="story-section">
          {story && (
            <div>
              <h2>História Gerada:</h2>
              <p id="story-text" className="story-text">{story}</p>
              <button onClick={() => handlePlayStory(story)} className="audio-button">Ouvir História</button>
            </div>
          )}
        </div>
        <div className="history-section">
          <h2>Histórico de Historinhas</h2>
          <ul className="stories-list">
            {stories.map((storyItem) => (
              <li key={storyItem.id} className="story-item">
                <p className="story-text">{storyItem.story}</p>
                <button onClick={() => handlePlayStory(storyItem.story)} className="audio-button">Ouvir História</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="bottom-menu">
        <button onClick={() => navigate('/home')}>
          <i className="fas fa-home"></i>
        </button>
        <button onClick={() => navigate('/historinhas')}>
          <i className="fas fa-book"></i>
        </button>
        <button onClick={() => navigate('/cantinhodacrianca')}>
          <i className="fas fa-child"></i>
        </button>
        <button onClick={handleLogout}>
          <i className="fas fa-sign-out-alt"></i>
        </button>
      </div>
    </div>
  );
};

export default Historinhas;
