import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './TenhoDuvida.css';

const TenhoDuvida = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const email = localStorage.getItem('email');
        const token = localStorage.getItem('token');

        if (email && token) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-questions`, {
            params: { email },
            headers: { Authorization: `Bearer ${token}` }
          });
          setHistory(response.data.questions);
        }
      } catch (error) {
        console.error('Erro ao buscar histórico', error);
      }
    };

    fetchHistory();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (question.trim() === '') {
      alert('A pergunta não pode estar vazia.');
      return;
    }
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/ask`, { question }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAnswer(response.data.answer);

      const email = localStorage.getItem('email');
      if (email && token) {
        const historyResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-questions`, {
          params: { email },
          headers: { Authorization: `Bearer ${token}` }
        });
        setHistory(historyResponse.data.questions);
      }
    } catch (error) {
      console.error('Erro ao enviar a pergunta', error);
      alert('Erro ao enviar a pergunta. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    navigate('/login');
  };

  return (
    <div className="container">
      <div className="main-content">
        <h1>Faça sua pergunta:</h1>
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <label htmlFor="question">Pergunta:</label>
            <input
              id="question"
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Enviando...' : 'Enviar'}
          </button>
        </form>
        {answer && (
          <div className="answer">
            <h3>Resposta:</h3>
            <p>{answer}</p>
          </div>
        )}
        <h2>Histórico de Perguntas:</h2>
        <ul className="history-list">
          {history.length === 0 ? (
            <p>Sem perguntas anteriores.</p>
          ) : (
            history.map((item, index) => (
              <li key={index} className="history-item">
                <p><strong>Pergunta:</strong> {item.question}</p>
                <p><strong>Resposta:</strong> {item.answer}</p>
              </li>
            ))
          )}
        </ul>
      </div>
      <div className="bottom-menu">
        <button onClick={() => navigate('/tenho-duvida')}>
          <i className="fas fa-question"></i>
        </button>
        <button onClick={() => navigate('/historinhas')}>
          <i className="fas fa-book"></i>
        </button>
        <button onClick={() => navigate('/faca-um-desenho')}>
          <i className="fas fa-pen"></i>
        </button>
        <button onClick={() => navigate('/cantinho-da-crianca')}>
          <i className="fas fa-child"></i>
        </button>
        <button onClick={handleLogout}>
          <i className="fas fa-sign-out-alt"></i>
        </button>
      </div>
    </div>
  );
};

export default TenhoDuvida;
