import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import './CantinhodaCrianca.css';

// Lista de perguntas com imagens e respostas
const questions = [
  { image: 'apple.png', answer: 'Maçã' },
  { image: 'banana.png', answer: 'Banana' },
  { image: 'car.png', answer: 'Carro' },
  { image: 'dog.png', answer: 'Cachorro' }
];

// Função para usar a API do ResponsiveVoice
const speak = (text) => {
  if (window.responsiveVoice) {
    window.responsiveVoice.speak(text, "Brazilian Portuguese Female");
  } else {
    console.error("ResponsiveVoice não está disponível");
  }
};

const CantinhoDaCrianca = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [feedback, setFeedback] = useState('');
  const navigate = useNavigate();

  // Atualiza a pergunta e inicia a leitura
  useEffect(() => {
    if (questions[currentQuestion]) {
      speak(questions[currentQuestion].answer);
    }
  }, [currentQuestion]);

  const handleOptionChange = (answer) => {
    setSelectedOption(answer);
  };

  const handleSubmit = () => {
    if (selectedOption === questions[currentQuestion].answer) {
      setFeedback('Parabéns, você acertou!');
      speak('Parabéns, você acertou!');
    } else {
      setFeedback('Hummm, não foi desta vez. Tente novamente.');
      speak('Hummmm, não foi desta vez. Tente novamente.');
    }
  };

  const handleNext = () => {
    setSelectedOption('');
    setFeedback('');
    setCurrentQuestion((prev) => (prev + 1) % questions.length);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    navigate('/login');
  };

  return (
    <div className="container">
      <Sidebar onLogout={handleLogout} />
      <div className="mainContent">
        <h1>Cantinho da Criança</h1>
        <p>Bem-vindo ao Cantinho da Criança! Explore o questionário com imagens e ouça os nomes.</p>
        <h2>Escolha a opção correta:</h2>
        <div className="questionContainer">
          <div className="optionsContainer">
            {questions.map((question, index) => (
              <label 
                key={index} 
                className={`optionLabel ${selectedOption === question.answer ? 'selected' : ''}`}
              >
                <input
                  type="radio"
                  value={question.answer}
                  checked={selectedOption === question.answer}
                  onChange={() => handleOptionChange(question.answer)}
                  className="optionInput"
                />
                <img
                  src={`/images/${question.image}`}
                  alt={question.answer}
                  className={selectedOption === question.answer ? 'optionImageSelected' : 'optionImage'}
                />
              </label>
            ))}
          </div>
          <div className="buttonContainer">
            <button onClick={handleSubmit} className="button">Verificar</button>
            <button onClick={handleNext} className="button">Próxima Pergunta</button>
          </div>
          {feedback && <p className="feedback">{feedback}</p>}
        </div>
      </div>
      <div className="bottom-menu">
        <button onClick={() => navigate('/tenho-duvida')}>
          <i className="fas fa-question-circle"></i>
        </button>
        <button onClick={() => navigate('/historinhas')}>
          <i className="fas fa-book"></i>
        </button>
        <button onClick={() => navigate('/cantinho-da-crianca')}>
          <i className="fas fa-child"></i>
        </button>
        <button onClick={handleLogout}>
          <i className="fas fa-sign-out-alt"></i>
        </button>
      </div>
    </div>
  );
};

export default CantinhoDaCrianca;
