import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Login from './components/Login';
import Signup from './components/Signup';
import TenhoDuvida from './components/TenhoDuvida';
import Historinhas from './components/Historinhas';
import CantinhodaCrianca from './components/CantinhodaCrianca';
import FacaumDesenho from './components/FacaumDesenho';
import './App.css'; // Importa o CSS global

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const hideSidebar = location.pathname === '/login' || location.pathname === '/signup';

  // Redirecionar para /login quando o componente for montado
  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/login');
    }
  }, [location.pathname, navigate]);

  return (
    <div className="app-container">
      {!hideSidebar && (
        <Sidebar onLogout={() => {
          localStorage.removeItem('token');
          localStorage.removeItem('email');
          navigate('/login');
        }} />
      )}
      <div className={`main-content ${hideSidebar ? 'full-width' : ''}`}>
        <Outlet /> {/* Este Outlet renderiza o componente de rota atual */}
      </div>
      {!hideSidebar && (
        <div className="bottom-menu">
          <button onClick={() => navigate('/tenho-duvida')}>
            <i className="fas fa-question"></i> 
          </button>
          <button onClick={() => navigate('/historinhas')}>
            <i className="fas fa-book"></i>
          </button>
          <button onClick={() => navigate('/faca-um-desenho')}>
            <i className="fas fa-pen"></i>
          </button>
          <button onClick={() => navigate('/cantinho-da-crianca')}>
            <i className="fas fa-child"></i>
          </button>
          <button onClick={() => {
            localStorage.removeItem('token');
            localStorage.removeItem('email');
            navigate('/login');
          }}>
            <i className="fas fa-sign-out-alt"></i>
          </button>
        </div>
      )}
    </div>
  );
};

const App = () => (
  <Router>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route element={<Layout />}>
        <Route path="/tenho-duvida" element={<TenhoDuvida />} />
        <Route path="/historinhas" element={<Historinhas />} />
        <Route path="/cantinho-da-crianca" element={<CantinhodaCrianca />} />
        <Route path="/faca-um-desenho" element={<FacaumDesenho />} />
      </Route>
      {/* Adicionando uma rota que redireciona para /login */}
      <Route path="/" element={<Layout />} />
    </Routes>
  </Router>
);

export default App;
