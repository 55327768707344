import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = ({ onLogout }) => {
  return (
    <div className="sidebar">
      <img src="/logotipo.png" alt="Mamãe Conecta" className="logo" />
      <ul className="menu">
        <li><Link to="/tenho-duvida">Tenho uma dúvida</Link></li>
        <li><Link to="/historinhas">Histórinhas</Link></li>
        <li><Link to="/faca-um-desenho">Faça um desenho</Link></li>
        <li><Link to="/cantinho-da-crianca">Cantinho da Criança</Link></li>
        <li><button onClick={onLogout} className="logout-button">Sair</button></li>
      </ul>
    </div>
  );
};

export default Sidebar;
