import React, { useRef, useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import './FacaumDesenho.css';

const FacaumDesenho = () => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [color, setColor] = useState('black');
  const [lineWidth, setLineWidth] = useState(5);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const navigate = useNavigate();

  // Configura o canvas
  const setupCanvas = () => {
    const canvas = canvasRef.current;
    canvas.width = canvas.parentElement.offsetWidth;
    canvas.height = window.innerHeight - 150; // Ajusta a altura para deixar espaço para o menu inferior
    const context = canvas.getContext('2d');
    context.strokeStyle = color;
    context.lineWidth = lineWidth;
    context.lineCap = 'round';
    contextRef.current = context;
  };

  // Atualiza as configurações de desenho
  const updateDrawingSettings = () => {
    const context = contextRef.current;
    if (context) {
      context.strokeStyle = color;
      context.lineWidth = lineWidth;
    }
  };

  // Inicia o desenho
  const startDrawing = (e) => {
    setIsDrawing(true);
    draw(e);
  };

  // Para o desenho
  const stopDrawing = () => {
    setIsDrawing(false);
    contextRef.current.beginPath();
  };

  // Desenha no canvas
  const draw = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    contextRef.current.lineTo(x, y);
    contextRef.current.stroke();
    contextRef.current.beginPath();
    contextRef.current.moveTo(x, y);
  };

  // Limpa o canvas
  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = contextRef.current;
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  // Configura o canvas quando o componente é montado
  useEffect(() => {
    setupCanvas();
    window.addEventListener('resize', setupCanvas);

    // Bloqueia a rolagem quando a página é carregada
    document.body.style.overflow = 'hidden';

    // Limpa a configuração quando o componente é desmontado
    return () => {
      window.removeEventListener('resize', setupCanvas);
      document.body.style.overflow = 'auto';
    };
  }, []);

  // Atualiza as configurações de desenho sempre que a cor ou o tamanho da linha mudam
  useEffect(() => {
    updateDrawingSettings();
  }, [color, lineWidth]);

  // Lida com eventos de toque em dispositivos móveis
  const handleTouchStart = (e) => {
    e.preventDefault(); // Evita o comportamento padrão do toque
    startDrawing(e.touches[0]); // Usa o primeiro toque
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    stopDrawing();
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    draw(e.touches[0]); // Usa o primeiro toque
  };

  // Lida com eventos de mouse e toque
  const handleMouseDown = (e) => startDrawing(e);
  const handleMouseUp = (e) => stopDrawing();
  const handleMouseMove = (e) => draw(e);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    navigate('/login');
  };

  return (
    <div className="drawing-container">
      <Sidebar onLogout={handleLogout} />
      <div className="main-content">
        <h1>Desenhe um animal que você goste!</h1>
        <div className="controls">
          <label>
            Cor:
            <input
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
          </label>
          <label>
            Tamanho do Pincel:
            <input
              type="number"
              min="1"
              max="20"
              value={lineWidth}
              onChange={(e) => setLineWidth(Number(e.target.value))}
            />
          </label>
          <button onClick={clearCanvas}>Limpar Quadro</button>
        </div>
        <div className="drawing-canvas-container">
          <canvas
            ref={canvasRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
            className="drawing-canvas"
          />
        </div>
      </div>
      <div className="bottom-menu">
        <button onClick={() => navigate('/home')}>
          <i className="fas fa-home"></i>
        </button>
        <button onClick={() => navigate('/historinhas')}>
          <i className="fas fa-book"></i>
        </button>
        <button onClick={() => navigate('/cantinhodacrianca')}>
          <i className="fas fa-child"></i>
        </button>
        <button onClick={handleLogout}>
          <i className="fas fa-sign-out-alt"></i>
        </button>
      </div>
    </div>
  );
};

export default FacaumDesenho;
